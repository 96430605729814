import React from "react";
import './CalculatorNumberInputRow.scss';

export class CalculatorNumberInputRow extends React.Component<{
    name: string,
    onChange: (v: number) => void
}> {
    state = {
        value: 100,
        error: null
    };

    render() {
        return <div
            className="flex items-start justify-between relative w-full flex-[0_0_auto]">
            <div
                className="relative w-fit mt-[-1.00px] [font-family:'Aeonik-Medium',Helvetica] font-medium text-white text-xl text-center tracking-[0] leading-[42px] whitespace-nowrap">
                {this.props.name}:
            </div>
            <div className="flex w-1/2 relative">
                <div
                    className={`block text-center absolute [font-family:'Aeonik-Bold',Helvetica] top-[-5px] text-[white] w-full input-error ${this.state.error ? 'input-error-show' : 'input-error-hidden'}`}>
                    {this.state.error}
                </div>
                <input
                    value={this.state.value}

                    className={"border-glow block text-center bg-[black] text-[white] [font-family:'Aeonik-Bold',Helvetica] font-bold text-md leading-10 shadow-[inset_0_0_0_2px_#ff5ee5]  whitespace-nowrap border-0 rounded-lg caret-[#ff5ee5] w-full"}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    onChange={(e) => {
                        const v = parseInt(e.target.value);
                        if (!v) {
                            this.setState({value: ''});
                            return;
                        }
                        if (v < 50) {
                            this.setState({value: v, error: 'We make 50+'});
                        } else if (v > 999999) {
                            this.setState({error: 'Please stop!!'});
                        } else if (v > 100000) {
                            this.setState({value: v, error: 'Please stop!!'});
                        } else if (v > 10000) {
                            this.setState({value: v, error: 'You do are crazy'});
                        } else if (v > 1000) {
                            this.setState({value: v, error: 'Oh yes, let\'s do it'});
                        } else {
                            this.setState({value: v, error: null});
                        }
                        this.props.onChange(parseInt(e.currentTarget.value))
                    }}></input>
            </div>
        </div>;
    }
}