import React, { useState } from 'react';
import './Toggle.css';

export type ToggleValue = string | number | boolean;

export interface ToggleOption {
    name: string;
    value: ToggleValue;
}

export interface ToggleProps {
    options: ToggleOption[];
    onToggle: (value: ToggleValue) => void;
}

const Toggle: React.FC<ToggleProps> = ({ options, onToggle }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index: number, value: ToggleValue) => {
        setActiveIndex(index);
        onToggle(value);
    };

    return (
        <div className="toggle-container border-glow">
            <div
                className="toggle-slider"
                style={{ transform: `translateX(${activeIndex * 100}%)`, width: `${100/options.length}%` }}
            />
            {options.map((option, index) => (
                <div
                    key={option.value as string}
                    className={`text-xs sm:text-[1rem] leading-8 sm:leading-10 [font-family:'Aeonik-Bold',Helvetica] toggle-option ${index === activeIndex ? 'active' : ''}`}
                    onClick={() => handleClick(index, option.value)}
                >
                    {option.name}
                </div>
            ))}
        </div>
    );
};

export default Toggle;
