import React, {createRef, ImgHTMLAttributes} from "react";
import './CalculatorScreen.scss';
import {Calculator} from "./Calculator";
import {CalculatorPickClothType, ClothType, clothTypes} from "./Calculator/CalculatorPickClothType";
import ModalVideo from 'react-modal-video';

export class CalculatorScreen extends React.Component {
    state = {
        clothType: clothTypes[0],
        onboarding: true,
        coolnessLevel: 1,
        open: false,
    }
    image = createRef<HTMLImageElement>();

    render() {

        return <div
            ref={this.image}
            className={`calculator-screen background flex w-full flex-col items-center justify-center ${this.state.onboarding ? 'onboard' : ''}`}>
            <CalculatorPickClothType onPick={(v) => {
                this.setState({clothType: v, onboarding: false})
                setTimeout(() => this.image.current?.scrollIntoView({behavior: 'smooth', block: 'end'}), 100);
            }
            }/>
            <div className="h-[100vh] sm:h-fit my-2 w-full flex flex-col sm:flex-row items-center relative">
                <ModalVideo channel='vimeo' autoplay={true} isOpen={this.state.open} videoId={'991359609'} ratio={'9:16'}
                            onClose={() => this.setState({open: false})}/>
                <div
                    style={{transform: 'translateX(calc(50%)) rotate(-90deg)'}}
                    className="absolute [font-family:'Aeonik-Medium',Helvetica] font-bold text-white top-[20%] right-[0.575rem] z-10 px-4 py-2 rounded-xl border">
                    <button className="btn-primary w" onClick={() => this.setState({open: true})}>🔮 CHECK OUR QUALITY 🔮</button>
                </div>
                <div
                    style={{transform: 'translateX(calc(-50%)) rotate(90deg)'}}
                    className="absolute md:hidden [font-family:'Aeonik-Medium',Helvetica] font-bold text-white top-[20%] left-[0.575rem] z-10 px-4 py-2 rounded-xl border">
                    <a href={'https://calendly.com/luhovska-merch/30min'} target={"_blank"}>🍭 BOOK A CALL 🍭</a>
                </div>
                <div
                    style={{transform: 'translateX(calc(50%)) rotate(-90deg)'}}
                    className="absolute hidden md:block [font-family:'Aeonik-Medium',Helvetica] font-bold text-white top-[60%] right-[0.575rem] z-10 px-4 py-2 rounded-xl border">
                    <a href={'https://calendly.com/luhovska-merch/30min'} target={"_blank"}>🍭 BOOK A CALL 🍭</a>
                </div>
                <div
                    className="flex-1 p4 sm:w-full flex flex-col items-center justify-center h-full w-full relative sm:order-2 relative">
                    <img src={this.state.clothType.img} alt=""
                         className="absolute p4 sm:relative max-h-full max-w-[30rem]"/>
                    {this.state.coolnessLevel > 1 ? <>
                        <div
                            className="absolute w-1/5 overflow-hidden rounded-[50%] shadow-[inset_0_0_0_2px_#ff5ee5] border-solid top-[20%] left-[60%]">
                            <video src="/img/pepe-coolness.mp4" autoPlay={true} muted={true} loop={true}></video>

                        </div>
                        <div
                            style={{
                                'transform': 'translate(-2.5%, -2.5%) scale(1.1)'
                            }}
                            className="absolute w-1/5 overflow-hidden rounded-[50%] shadow-[inset_0_0_0_6px_#ff5ee5] sm:shadow-[inset_0_0_0_0.65rem_#ff5ee5] top-[20%] left-[60%] aspect-square">
                            <img src="/img/level-2-text.png" alt=""/>
                        </div>

                    </> : ''}
                    {this.state.coolnessLevel > 2 ? <>
                        <div
                            className="absolute w-1/5 overflow-hidden rounded-[50%] shadow-[inset_0_0_0_2px_#ff5ee5] top-[70%] left-[20%] aspect-square">
                            <img src="/img/chanel-embroydery.png" alt=""/>
                        </div>
                        <div
                            style={{
                                'transform': 'translate(-2.5%, -2.5%) scale(1.1)'
                            }}
                            className="absolute w-1/5 overflow-hidden rounded-[50%] shadow-[inset_0_0_0_6px_#ff5ee5] sm:shadow-[inset_0_0_0_0.65rem_#ff5ee5] top-[70%] left-[20%] aspect-square">
                            <img src="/img/level-3-text.png" alt=""/>
                        </div>
                    </> : ''}
                </div>
                <div
                     className="px-4 p-4 sm:p-4 sm:ml-10 w-full sm:max-w-[40%] sm:min-w-[30rem] sm:max-h-full sm:order-1">
                    <Calculator
                        onCoolnessLeveChange={(i) => this.setState({coolnessLevel: i})}
                        onAnyChange={() => this.setState({onboarding: false})} type={this.state.clothType}
                        name={this.state.clothType.name} description={this.state.clothType.description}/>
                </div>
            </div>
        </div>
    }
}