import React, {createRef, LegacyRef} from "react";
import {CalculatorToggleInputRow} from "./Calculator/CalculatorToggleInputRow";
import {CalculatorNumberInputRow} from "./Calculator/CalculatorNumberInputRow";
import {ClothType, ClothTypePrice} from "./Calculator/CalculatorPickClothType";
import {DeliveryPriceCalculator, productConfigs} from "./Calculator/DeliveryPriceCalculator";
import {Region} from "./Calculator/Configuration";
import {Input, PriceCalculator, Result} from "./Calculator/PriceCalculator";
import Toggle from "./lib/Toggle";
import {Video} from "./lib/Video";

type CalculatorUserInput = {
    overallPattern?: boolean,
    coolnessLevel?: number,
    deliveryRegion?: Region,
    itemsCount?: number
};

type State = Required<CalculatorUserInput> & { explainerOpened: boolean };

export class Calculator extends React.Component<{
    onCoolnessLeveChange: (i: number) => void,
    onAnyChange: () => void,
    name: string,
    description: string[],
    type: ClothType
}> {
    state: State = {
        overallPattern: true,
        deliveryRegion: 'OTHER' as Region,
        coolnessLevel: 1,
        itemsCount: 100,
        explainerOpened: false
    };
    explainerScroller = createRef<HTMLDivElement>();
    deliveryPriceCalculator = new DeliveryPriceCalculator(productConfigs);
    priceCalculator = new PriceCalculator();
    input: Input = {
        designCost: 1000,
        processManagementCost: 500,
        manufacturingPerItemCost: 8,
        deliveryCost: 300,
        itemsCount: 100,
        agencyPerItemFeePercent: 20,
    };
    explainerOpened: boolean = false;
    calculatorRef = createRef<HTMLDivElement>();

    update(data: CalculatorUserInput) {
        this.props.onAnyChange();
        this.setState(data);
    }

    render() {
        const manufacturingPerItemCost = (this.state.overallPattern ? this.props.type.prices.overall : this.props.type.prices.standard) + (this.state.coolnessLevel - 1) * 3;
        const deliveryCost = this.deliveryPriceCalculator.calculate(this.props.type.type, this.state.deliveryRegion, this.state.itemsCount);
        const input = {...this.input, manufacturingPerItemCost, deliveryCost, itemsCount: this.state.itemsCount};
        const result = this.priceCalculator.calculate(input);
        let main = <>
            <div
                className="flex flex-col items-center p-2.5 relative self-stretch w-full rounded-[20px] border-[2px] border-solid border-[#ff5ee5]">
                <div
                    className="relative h-11 [font-family:'Druk_Wide_Trial-Bold',Helvetica] font-bold text-white text-xl text-center">
                    {this.props.name}
                </div>
                <p className="relative w-fit [font-family:'Aeonik-Medium',Helvetica] font-medium text-center text-white text-md tracking-[0] leading-5">
                    {this.props.description.map(k => <>{k}<br/></>)}
                    <Video></Video>
                </p>
            </div>
            <CalculatorToggleInputRow
                name={'OVERALL PATTERN'}
                options={[
                    {
                        name: "YES",
                        value: true
                    },
                    {
                        name: "NO",
                        value: false
                    },
                ]}
                onToggle={(v) => {
                    this.update({overallPattern: v as boolean});
                }}/>
            <CalculatorToggleInputRow
                name={'COOLNESS LEVEL'}
                options={[
                    {
                        name: "1",
                        value: 1
                    }, {
                        name: "2",
                        value: 2
                    },
                    {
                        name: "3",
                        value: 3
                    },
                ]}
                onToggle={(v) => {
                    this.props.onCoolnessLeveChange(v as number);
                    this.update({coolnessLevel: v as number});
                }}/>
            <CalculatorToggleInputRow
                name={'DELIVERY TO'}
                options={[
                    {
                        name: "EU / USA",
                        value: 'OTHER'
                    },
                    {
                        name: "ASIA",
                        value: 'Asia'
                    },
                ]}
                onToggle={(v) => {
                    this.update({deliveryRegion: v as Region});
                }}/>
            <CalculatorNumberInputRow name={'QUANTITY'} onChange={(v) => {
                this.update({itemsCount: v as number})
            }}></CalculatorNumberInputRow>
            <div className="flex items-end justify-center gap-4 relative self-stretch w-full flex-[0_0_auto]">
                <div className="inline-flex flex-col items-end relative w-1/2">
                    <div
                        className="relative self-stretch h-[42px] mt-[-1.00px] [font-family:'Druk_Wide_Trial-Bold',Helvetica] font-medium text-white text-md text-center tracking-[0]  whitespace-nowrap flex items-center justify-center">
                        PER ITEM
                    </div>
                    <div
                        className="flex items-center justify-center px-[1rem] py-0 relative self-stretch w-full flex-[0_0_auto] mb-[-3.00px] ml-[-3.00px] mr-[-3.00px] bg-[#ff5ee5] rounded-[15px] border-[2px] border-solid">
                        <div
                            className="relative w-fit mt-[-3.00px] ml-[-1.50px] [font-family:'Aeonik-Bold',Helvetica] font-bold text-white text-2xl tracking-[0] leading-[4rem] whitespace-nowrap">
                            {result ? result.pricePerItem : '696969'}$
                        </div>
                    </div>
                </div>
                <div className="flex-col items-center flex relative flex-1 grow w-1/2">
                    <div
                        className="relative self-stretch h-[42px] mt-[-1.00px] [font-family:'Druk_Wide_Trial-Bold',Helvetica] font-medium text-white text-md text-center tracking-[0] whitespace-nowrap flex items-center justify-center">
                        TOTAL
                    </div>
                    <div
                        className="flex items-center justify-center px-[1rem] py-0 relative self-stretch w-full flex-[0_0_auto] mb-[-3.00px] ml-[-3.00px] mr-[-3.00px] bg-[#ff5ee5] rounded-[15px] border-[2px] border-solid">
                        <div
                            className="relative w-fit mt-[-3.00px] [font-family:'Aeonik-Bold',Helvetica] font-bold text-white text-2xl tracking-[0] leading-[4rem] whitespace-nowrap">
                            {result ? result.fullPrice : '696969'}$
                        </div>
                    </div>
                </div>
            </div>
        </>;
        return <div
            className="bg-black overflow-hidden calculator relative rounded-[30px] border-[2px] border-solid border-[#ff5ee5]">
            <div
                className="flex flex-col items-center gap-4 justify-between p-4">
                {main}
                <div
                    className="flex items-end justify-center gap-4 relative self-stretch w-full flex-[0_0_auto] h-4">
                </div>
            </div>
            <div className="overflow-hidden calculator absolute w-full top-0 z-10" ref={this.explainerScroller}>
                <div ref={this.calculatorRef} className="flex flex-col items-center gap-4 justify-between p-4">
                    {main}
                </div>
                <div
                    onScroll={this.toggleExplanation()}
                    onClick={this.toggleExplanation()}
                    className="w-full bg-[#ff5ee5] h-10 rounded-3xl flex justify-center items-center cursor-pointer">
                    <div
                        className="relative self-stretch mt-[-1.00px] [font-family:'Druk_Wide_Trial-Bold',Helvetica] font-medium text-white text-md text-center tracking-[0] whitespace-nowrap flex items-center justify-center">
                        {this.state.explainerOpened ? '🔽 HIDE EXPLANATION 🔽' : 'CLICK 🔼 🤑EXPLAIN PRICES 🤑 🔼 CLICK'}
                    </div>
                </div>

                <div className="flex flex-col items-center gap-2 justify-between p-4">
                    {this.explanationCalculations('MANUFACTURING COST',
                        'COST PER ITEM',
                        `${manufacturingPerItemCost}$`,
                        'ITEMS COUNT',
                        `${input.itemsCount}`,
                        `${result.manufacturingCost}$`
                    )}
                    {this.explanationRow('DELIVERY', input.deliveryCost)}
                    {this.explanationRow('DESIGN', input.designCost)}
                    {this.explanationRow('PROCESS MANAGEMENT', input.processManagementCost)}
                    {this.explanationCalculations('AGENCY FEE',
                        'MANUFACTURING COST',
                        `${result.manufacturingCost}$`,
                        'FEE',
                        `${input.agencyPerItemFeePercent}%`,
                        `${result.agencyFee}$`
                    )}
                </div>

            </div>
        </div>;
    }

    private toggleExplanation() {
        return () => {
            if (this.state.explainerOpened) {
                this.calculatorRef.current?.scrollIntoView({block: "end", behavior: "smooth"});
                this.setState({explainerOpened: false});
            } else {
                this.explainerScroller.current?.scrollIntoView({block: "end", behavior: "smooth"});
                this.setState({explainerOpened: true});
            }
        };
    }

    private explanationCalculations(name: string, firstParameterName: string, firstParameterValue: string, secondParameterName: string, secondParameterValue: string, result: string) {
        return <div className="flex items-center justify-around gap-1 relative self-stretch w-full flex-[0_0_auto]">
            <div
                className="flex flex-col items-center p-2.5 relative self-stretch w-full rounded-[20px] border-[2px] border-solid border-[#ff5ee5]">
                <div
                    className="relative  [font-family:'Aeonik-Bold',Helvetica] font-bold text-white text-md  text-center">
                    {name}
                </div>
                <hr className="w-full my-2 text-black border-[#ff5ee5]"/>
                <div className="flex items-start justify-between relative w-full flex-[0_0_auto]">
                    <div className="inline-flex flex-col items-end relative">
                        <div
                            className="relative [font-family:'Aeonik-Bold',Helvetica] text-xs text-white text-md text-center tracking-[0]  whitespace-nowrap flex items-center justify-center">
                            {firstParameterName}
                        </div>
                        <div
                            className="flex w-full justify-center relative flex-[0_0_auto] px-4 py-1 bg-[#ff5ee5] rounded-[0.5rem]">
                            <div
                                className="relative w-fit [font-family:'Aeonik-Bold',Helvetica] font-bold text-white text-md tracking-[0] whitespace-nowrap">
                                {firstParameterValue}
                            </div>
                        </div>
                    </div>
                    <div
                        className="relative [font-family:'Aeonik-Bold',Helvetica] text-3xl text-white text-md text-center tracking-[0]  whitespace-nowrap flex items-center justify-center">
                        *
                    </div>
                    <div className="flex-col items-center flex relative">
                        <div
                            className="relative [font-family:'Aeonik-Bold',Helvetica] text-xs text-white text-md text-center tracking-[0] whitespace-nowrap flex items-center justify-center">
                            {secondParameterName}
                        </div>
                        <div
                            className="flex w-full items-center justify-center relative flex-[0_0_auto] px-4 py-1 bg-[#ff5ee5] rounded-[0.5rem]">
                            <div
                                className="relative w-fit [font-family:'Aeonik-Bold',Helvetica] font-bold text-white text-md tracking-[0] whitespace-nowrap">
                                {secondParameterValue}
                            </div>
                        </div>
                    </div>
                    <div
                        className="relative [font-family:'Aeonik-Bold',Helvetica] text-3xl text-white text-md text-center tracking-[0]  whitespace-nowrap flex items-center justify-center">
                        =
                    </div>
                    <div className="flex-col items-center justify-center flex relative h-full w-1/3">
                        <div
                            className="flex w-full items-center justify-center relative flex-[0_0_auto] px-4 py-1 bg-[#ff5ee5] rounded-[0.5rem]">
                            <div
                                className="relative w-fit mt-[-3.00px] [font-family:'Aeonik-Bold',Helvetica] font-bold text-white text-xl tracking-[0] leading-[2rem] whitespace-nowrap">
                                {result}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>;
    }

    private explanationRow(name: string, value: string | number) {
        return <div
            className="flex items-start justify-between relative w-full flex-[0_0_auto] px-4">
            <div
                className="relative w-fit mt-[-1.00px] [font-family:'Aeonik-Medium',Helvetica] font-medium text-white text-xl text-center tracking-[0] leading-[42px] whitespace-nowrap">
                {name}:
            </div>
            <div
                className="flex items-center justify-center px-[1rem] py-0 relative self-stretch w-1/3 ml-[-3.00px] mr-[-3.00px] bg-[#ff5ee5] rounded-[0.5rem] border-[2px] border-solid">
                <div
                    className="relative w-fit mt-[-3.00px] [font-family:'Aeonik-Bold',Helvetica] font-bold text-white text-xl tracking-[0] leading-[2rem] whitespace-nowrap">
                    {value}$
                </div>
            </div>
        </div>;
    }
}