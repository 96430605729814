import React from "react";
import Toggle, {ToggleOption, ToggleValue} from "../lib/Toggle";

export class CalculatorToggleInputRow extends React.Component<{
    name: string,
    options: ToggleOption[],
    onToggle: (v: ToggleValue) => void
}> {
    render() {
        return <div
            className="flex items-start justify-between relative w-full flex-[0_0_auto] justify-center items-center">
            <div
                className="relative w-fit mt-[-1.00px] [font-family:'Aeonik-Medium',Helvetica] font-medium text-white text-md sm:text-xl text-center tracking-[0] whitespace-nowrap">
                {this.props.name}:
            </div>
            <div className="flex w-1/2">
                <div className="flex items-center justify-end relative flex-1 self-stretch w-full grow">
                    <Toggle
                        options={this.props.options}
                        onToggle={this.props.onToggle}
                    ></Toggle>
                </div>
            </div>
        </div>;
    }
}