import React from "react";
import {ProductType} from "./Configuration";

export type ClothTypePrice = {
    overall: number,
    standard: number
};

export interface ClothType {
    img: string;
    name: string;
    type: ProductType;
    prices: ClothTypePrice;
    description: string[];
}

export const clothTypes: ClothType[] = [{
    img: '/img/tshirt-square.png',
    name: 'T-SHIRTS',
    type: 'T-shirt',
    prices: {
        overall: 8,
        standard: 5
    },
    description: [
        "🌸 100% COTTON",
        "😎 COOL UNISEX DROP-SHOULDER STYLE",
        "🗓️ 1,5 MONTHS PRODUCTION & DELIVERY",
    ]
}, {
    img: '/img/hoodie-set.png',
    name: 'HOODIE COSTUME',
    type: 'Hoodie costume',
    prices: {
        overall: 23,
        standard: 15
    },
    description: [
        "🌸 100% COTTON",
        "😎 COOL UNISEX DROP-SHOULDER STYLE",
        "🗓️ 1,5 MONTHS PRODUCTION & DELIVERY",
    ]
}, {
    img: '/img/hoodie-square.png',
    name: 'HOODIE',
    type: 'Summer costume',
    prices: {
        overall: 15,
        standard: 10
    },
    description: [
        "🌸 100% COTTON",
        "😎 COOL UNISEX DROP-SHOULDER STYLE",
        "🗓️ 1,5 MONTHS PRODUCTION & DELIVERY",
    ]
}];

export class CalculatorPickClothType extends React.Component<{ onPick: (v: ClothType) => void }> {

    state = {picked: 0}

    render() {
        return <div className={`w-full flex flex-row overflow-scroll no-scrollbar`}>
            {clothTypes.map((i, k) => <div className="p-4 w-min"
                                           onClick={() => {
                                               this.setState({picked: k});
                                               this.props.onPick(clothTypes[k])
                                           }}>
                <div
                    className={`sm:w-40 sm:h-40 w-[8rem] h-[8rem] rounded-xl border border-glow relative cursor-pointer bg-black ${this.state.picked === k ? 'picked' : ''}`}>
                    <div className="onboarding-item absolute w-full h-full bg-[#0000003d]">
                        <div
                            style={{transform: 'translate(-50%, -50%) rotate(-45deg)'}}
                            className="relative h-11 [font-family:'Druk_Wide_Trial-Bold',Helvetica] font-bold text-white text-md sm:text-xl text-center top-1/2 left-1/2 flex items-center justify-center">
                            PICK ME
                        </div>
                    </div>
                    <img className="p-4 w-full max-h-full" src={i.img} alt=""/>
                </div>
            </div>)}
        </div>;
    }
}