export interface Input {
    designCost: number;
    processManagementCost: number;
    manufacturingPerItemCost: number;
    deliveryCost: number;
    itemsCount: number;
    agencyPerItemFeePercent: number;
}

export interface Result {
    pricePerItem: number;
    fullPrice: number;
    deliveryCost: number;
    manufacturingCost: number;
    agencyFee: number;
}

export class PriceCalculator {
    public calculate(input: Input): Result {
        const manufacturingCost = input.manufacturingPerItemCost * input.itemsCount;
        const agencyFee = manufacturingCost * (input.agencyPerItemFeePercent / 100);
        const fullPrice = manufacturingCost + agencyFee + input.designCost + input.processManagementCost + input.deliveryCost;
        const pricePerItem = fullPrice / input.itemsCount;

        return {
            pricePerItem: pricePerItem,
            fullPrice: fullPrice,
            deliveryCost: input.deliveryCost,
            manufacturingCost: manufacturingCost,
            agencyFee: agencyFee,
        };
    }
}