import React, {useState} from "react";

export const Video = () => {
    const [isOpen, setOpen] = useState(false)

    return (
        <React.Fragment>

        </React.Fragment>
    )
}