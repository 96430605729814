import {ProductType, Region} from "./Configuration";

interface ProductConfig {
    baseCost: number;
    threshold?: number;
    asiaCost: number;
    otherCost: number;
}

export const productConfigs: Record<ProductType, ProductConfig> = {
    'T-shirt': {
        baseCost: 100,
        threshold: 100,
        asiaCost: 1.5,
        otherCost: 2.5
    },
    'Hoodie costume': {
        baseCost: 0,
        asiaCost: 4,
        otherCost: 8
    },
    'Hoodie': {
        baseCost: 0,
        asiaCost: 2.5,
        otherCost: 5
    },
    'Summer costume': {
        baseCost: 200,
        threshold: 100,
        asiaCost: 2,
        otherCost: 4
    }
};

export class DeliveryPriceCalculator {
    private productConfigs: Record<ProductType, ProductConfig>;

    constructor(configs: Record<ProductType, ProductConfig>) {
        this.productConfigs = configs;
    }

    public calculate(type: ProductType, region: Region, itemCount: number): number {
        const config = this.productConfigs[type];

        if (!config) {
            throw new Error(`Invalid product type: ${type}`);
        }

        let totalCost = 0;

        if (config.baseCost > 0 && itemCount < (config.threshold || Infinity)) {
            totalCost = config.baseCost;
        } else {
            switch (region) {
                case 'Asia':
                    totalCost = config.asiaCost * itemCount;
                    break;
                case 'OTHER':
                    totalCost = config.otherCost * itemCount;
                    break;
            }
        }

        return totalCost;
    }
}
